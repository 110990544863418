<template>
  <section class="section-block">
    <SectionTitle title="Email 設定" btn="新增" @edit="dialog.create = true" />

    <el-table v-loading="loading" :data="emailList">
      <EmptyBlock slot="empty" />
      <el-table-column label="Email" prop="email" />
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideEdit
            @delete="dialog.delete=true, selectRow=scope.row"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="dialog.delete"
      @close="dialog.delete = false"
      @delete="deleteShopEmailNotify"
    />
    <EditNotifyEmailDialog
      v-if="dialog.create"
      @close="dialog.create = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import EditNotifyEmailDialog from './EditNotifyEmailDialog.vue'
import { GetShopEmailNotify, DeleteShopEmailNotify } from '@/api/shop'
import tableMixin from '@/mixin/table'

export default {
  name: 'NotifyEmailBlock',
  components: { EditNotifyEmailDialog },
  mixins: [tableMixin],
  data: () => ({
    emailList: [],
  }),
  computed: {
  },
  async mounted () {
    await this.refresh()
    // await this.getShopEmailNotify()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getShopEmailNotify()
      this.loading = false
    },

    async getShopEmailNotify () {
      const [res, err] = await GetShopEmailNotify({
        shopId: this.shop,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.emailList = res
    },

    async deleteShopEmailNotify () {
      const [, err] = await DeleteShopEmailNotify({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('刪除成功！')
      this.dialog.delete = false
      await this.refresh()
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
