<template>
  <div class="receive-notify">
    <PageTitle
      title="接收通知設定"
      icon="chevron_left"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <NotifyEmailBlock />
  </div>
</template>

<script>
import NotifyEmailBlock from './components/NotifyEmailBlock.vue'

export default {
  name: 'ReceiveNotify',
  components: {
    NotifyEmailBlock,
  },
  data: () => ({
    dialog: {
      email: false,
    },
    config: {},
  }),
}
</script>

<style lang="postcss" scoped>

</style>
